import * as React from 'react';
import PageTitle from "./PageTitle";
import PageHeader from "./PageHeader";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import Content from "./Content";
import Section from "./Section";

const SimplePageSection = ({simplePage}) => {
    return (
        <>
            <PageHeader>
                <PageTitle>
                    <h1>{simplePage.title}</h1>
                </PageTitle>
            </PageHeader>
            <Section hasMaxWidth>
                <Content isFullWidth>
                    <div>
                        {renderRichText(simplePage.body)}
                    </div>
                </Content>
            </Section>
        </>
    );
};

export default SimplePageSection;
